/* ----------------------------------------
    Animation Stylings
---------------------------------------- */
/* Fade Out Siblings */
.fade-siblings {
  &:hover {
    color: rgba(255, 255, 255, 0.5);
    transition: color 0.3s;
  }
  a:hover {
    color: #fff;
  }
}

/* Hover underline */
.underline {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 0;
    right: 0;
    bottom: -2px;
    background-color: #fff;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  &:hover::after {
    left: 0;
    right: auto;
    width: 100%;
  }
}

/* Box hover wipe */
.wipe {
  position: relative;
  transition: color 0.3s cubic-bezier(0.42, 0, 0.58, 1);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    z-index: -1;
    transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  }
  &:hover::before {
    left: 0;
    right: auto;
    width: 100%;
  }
  &.square {
    &::before {
      content: "Back \A to top";
      display: flex;
      align-items: center;
      justify-content: center;
      color: transparent;
      text-align: center;
      white-space: pre;
      pointer-events: none;
      z-index: 1;
      transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &:hover::before {
      color: #fff;
    }
  }
}

/* Mouse scroll animation */
.mouse {
  position: absolute;
  left: 50%;
  height: 48px;
  width: 28px;
  margin-left: -13px;
  border-radius: 11px 11px 15px 15px;
  border: 1px solid #fff;
  z-index: 1;

  span {
    display: block;
    height: 5px;
    width: 2px;
    margin: 6px auto;
    background: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    animation: scroll infinite 1s both;
  }
}
@keyframes scroll {
  0% {
    filter: opacity(100%);
    transform: translateY(0);
  }
  100% {
    filter: opacity(0%);
    transform: translateY(20px);
  }
}

/* Swipe touch animation */
.swipe {
  position: absolute;
  left: 50%;
  height: 48px;
  width: 28px;
  margin-left: -14px;
  z-index: 1;

  span {
    display: block;
    height: 20px;
    width: 20px;
    margin: 6px auto;
    border: 3px double #fff;
    border-radius: 10px;
    animation: swipe infinite 1s both;
  }
}
@keyframes swipe {
  0% {
    filter: opacity(100%);
    transform: translateY(10px);
  }
  100% {
    filter: opacity(0%);
    transform: translateY(0px);
  }
}

/* Particles.js background */
#particles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  canvas {
    display: block;
    filter: opacity(80%);
    transform: scale(1);
    transition: filter 0.8s ease, transform 1.4s ease;
  }
}
